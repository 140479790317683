import { IPopupOrder, IProduct } from "../../@types/interface";
import Image from 'next/image';
import React, { useContext, useEffect, useState } from "react";
import { checkUndefinedNumber, currencyFormatter, currencyWithNoFormat } from "../../utils/format";
import InputNumber from "../Input/InputNumber";
import Tab from "../Tab/tab";
import { getProductDetailByPdm, getProductDetailMix, getRefreshStockingCount, postTryOnSchedule } from "../../pages/api/product";
import ProductTab from "../Tab/product_tab";
import { DatePicker, Select } from "antd";
import { popUpsettings, settings } from "../../pages";
import { constant } from "../../utils/constant";
import { addToCart } from "../../utils/payment";
import PaymentContext from "../../store/PaymentContext";
import DescriptionTab from "../Tab/description_tab";
import ShopTab from "../Tab/shop_tab";
import Link from "next/link";
import { IOrderType } from "../../@types/IProductList";
import moment from "moment";
import dayjs from "dayjs";
import { STORAGE_KEYS } from "../../utils/config";
import environment from "../../utils/environment";
import PopupSizeGuide from "../PopupSizeGuide";
import VideoPlayer from "../VideoPlayer";
import AttributeValuesRender from "../AttributeValues";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import AppContext from "../../store/AppContext";
import { addKeyToImage } from "../../utils/utils";
import { calcTotalRentPrice, calcTotalSellPrice, checkAvailableTotalAmount, handleAccessories, handleAttributeValues, hasToIncreaseTotalAmount } from "../ProductList/utils";
import dynamic from "next/dynamic";
import { onChangeAttrTab } from "../Tab/utils";

const DynamicSlider = dynamic(() => import('react-slick'));

const PopupOrder: React.FC<IPopupOrder> = ({
    orderType,
    isOpen,
    setIsOpen,
    id,
    is_purchase,
    orderDate,
    detailData,
    is_product
}) => {
    const { windowWidth, branchs, holidays, workingDay } = useContext(AppContext);

    const [isAllowAction, setIsAllowAction] = useState(false);
    const [isAllowActionRent, setIsAllowActionRent] = useState(false);
    const router = useRouter();
    const [host, setHost] = useState("");
    const { setSellCarts, setRentCarts, sellCarts, rentCarts } = useContext(PaymentContext);
    const [isOpenSizeGuide, setIsOpenSizeGuide] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [detail, setDetail]: any = useState<IProduct>(detailData);
    const [date, setDate]: any = useState(orderDate || {
        takeDate: null,
        returnDate: null
    });
    const [openSignUp, setOpenSignUp] = useState(false);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [selectBranch, setSelectBranch]: any = useState(null);
    const [selectImage, setSelectImage] = useState({
        image: detail?.data?.files && detail?.data?.files[0]?.file_url ? environment.baseImageUrl + addKeyToImage(detail?.data?.files[0]?.file_url, 2, true, false, false, true) : "/asset/image/No_Image_Available.webp",
        alt: detail?.data?.files[0]?.alt ?? "",
        title: detail?.data?.files[0]?.alt ?? ""
    });

    const RenderTab = ({ detail, activeTab, branchs }: any) => {
        let list = [
            <ProductTab
                is_product={detail?.data?.is_product}
                onChange={onChangeAccessorieAmount}
                attribute={detail?.data?.attribute}
                accessories={detail?.accessories}
                handleChangeActive={onChangeAttribute}
                key={"productTab"} />,
            <DescriptionTab content={detail?.data?.description ?? ""} key={"descriptionTab"} />,
            <ShopTab shops={branchs} key={"shopTap"} is_Product={detail?.data?.is_product}/>
        ];

        if (detail?.selectProductDetail?.is_set === false) {

            list = [
                <DescriptionTab content={detail?.data?.description ?? ""} key={"descriptionTab"} />,
                <ShopTab shops={branchs} key={"shopTap"} is_Product={detail?.data?.is_product}/>
            ];
            //TH sản phẩm phối
            if (detail?.data?.is_product === false) {
                list = [
                    <ProductTab
                        is_product={false}
                        onChange={() => { }}
                        attribute={detail?.data?.attribute}
                        accessories={detail?.accessories}
                        handleChangeActive={onChangeAttribute}
                        key={"productTab"} />,
                    <DescriptionTab content={detail?.data?.description ?? ""} key={"descriptionTab"} />,
                    <ShopTab shops={branchs} key={"shopTap"} is_Product={detail?.data?.is_product}/>
                ];

                if (!detail?.data?.description) {


                    list = [
                        <ProductTab
                            is_product={false}
                            onChange={() => { }}
                            attribute={detail?.data?.attribute}
                            accessories={detail?.accessories}
                            handleChangeActive={onChangeAttribute}
                            key={"productTab"} />,
                        <ShopTab shops={branchs} key={"shopTap"} is_Product={detail?.data?.is_product}/>
                    ]
                }
            }

            if (!detail?.data?.description && detail?.data?.is_product === true) {
                list = [
                    <ShopTab shops={branchs} key={"shopTap"} is_Product={detail?.data?.is_product}/>
                ]
            }
        } else if (!detail?.data?.description) {
            list = [
                <ProductTab
                    is_product={detail?.data?.is_product}
                    onChange={onChangeAccessorieAmount}
                    attribute={detail?.data?.attribute}
                    accessories={detail?.accessories}
                    handleChangeActive={onChangeAttribute}
                    key={"productTab"} />,
                <ShopTab shops={branchs} key={"shopTap"} is_Product={detail?.data?.is_product}/>
            ];
        }

        return list[activeTab] ?? <></>
    }

    function updatePrice(data: any) {
        let cloneProductDetails = detail.data?.product_details?.map((d: any) => {
            let findProductDetail = data.product_details?.find((e: any) => e.product_code === d.product_code);
            //console.log('findProductDetail', findProductDetail);

            if (findProductDetail) {
                d.product_code = findProductDetail.product_code;
                d.code = findProductDetail.code;
                d.name = findProductDetail.name;
                d.amount = findProductDetail.amount;
                d.price = findProductDetail.price;
                d.rental_price = findProductDetail.rental_price;
                d.attribute = findProductDetail.attribute;
            }
            return d;
        })
        let product_detail = { ...detail.selectProductDetail };
        let accessories = [];
        if (detail.data?.is_product === false) {
            accessories = cloneProductDetails?.map((item: any) => {
                //count giá trị cho input
                item.count = item.product_amount ?? 0;
                return item;
            }) ?? [];
            product_detail = {
                ...detail.selectProductDetail,
                //product_code: data.product_code,
                rental_price: data.rental_price,
                price: data.price,
                amount: data.amount
            }
        } else {
            let currentSelectProductDetail = cloneProductDetails?.find((e: any) => e.product_code === detail.selectProductDetail?.product_code);
            accessories = handleAccessories(currentSelectProductDetail) ?? [];
            product_detail = {
                ...detail.selectProductDetail,
                //product_code: currentSelectProductDetail.product_code,
                rental_price: currentSelectProductDetail.rental_price,
                price: currentSelectProductDetail.price,
                amount: currentSelectProductDetail.amount
            }
        }
        //console.log('cloneProductDetails', cloneProductDetails);
        //console.log('accessories', accessories);

        let cloneDetail = {
            ...detail,
            data: {
                ...detail.data,
                origin_price: data.origin_price,
                origin_rental_price: data.origin_rental_price,
                price: data.price,
                rental_price: data.rental_price,
                amount: data.amount,
                product_details: cloneProductDetails,
            },
            selectProductDetail: product_detail ?? null,
            accessories: accessories,
            totalAmount: detail.data?.is_product === false ? (detail.data?.amount > 0 ? 1 : 1) : (checkAvailableTotalAmount(product_detail) ? 1 : 1),

        }
        return cloneDetail;
    }

    async function onChangeAttribute(main_id: any, attr: any) {
        let accessories = onChangeAttrTab(detail?.accessories, main_id, attr);
        //console.log('accessories', accessories);

        await callUpdatePrice(accessories, date);
    }

    async function callUpdatePrice(accessories: any, date: any) {
        let data: any = {
            product_mix_id: detail?.data.id,
            products: accessories?.map((acc: any) => {
                return {
                    product_code: acc.product_code,
                    attribute_main_id: acc.attribute_main_value_id,
                    attributes: acc.attribute?.map((attr: any) => attr.value)
                };
            }),

        }
        if (date.takeDate && date.returnDate) {
            data.from_date = date?.takeDate ? moment(date?.takeDate).format("DD/MM/YYYY HH:mm:ss") : "";
            data.to_date = date?.returnDate ? moment(date?.returnDate).format("DD/MM/YYYY HH:mm:ss") : "";
        }
        let res = await getProductDetailByPdm(data);
        if (res) {
            //console.log("detail", detail);

            let cloneDetail = updatePrice(res);
            //console.log("cloneDetail", cloneDetail);
            await setDetail(cloneDetail)
        }
    }

    async function loadProductDetail() {
        if (id && !detailData) {
            let param = `?product_detail_id=${id}`;
            if (is_product == false) {
                param = `?product_mix_id=${id}`;
            }
            let res: any = await getProductDetailMix(param);
            if (res) {

                let accessories = [];
                let product_detail = {
                    ...res?.product_details[0],
                    name: res.name,
                    is_product: res?.is_product ?? true
                };
                //TH sản phẩm phối
                if (res?.is_product === false) {
                    accessories = res?.product_details?.map((item: any) => {
                        //count giá trị cho input
                        item.count = item.product_amount;
                        return item;
                    }) ?? [];
                    product_detail = {
                        ...res?.product_details[0],
                        allowed_sell: res.allowed_sell,
                        id: res?.id,
                        temp_code: res?.product_details[0].code,
                        code: res?.code, accessories:
                            accessories, is_product: false,
                        name: res.name,
                        rental_price: res.rental_price,
                        price: res.price,
                        origin_rental_price: res.origin_rental_price,
                        origin_price: res.origin_price
                    }
                } else {
                    accessories = handleAccessories(res?.product_details[0]) ?? [];
                }

                await setSelectImage({
                    image: res?.files && res?.files[0]?.file_url ? environment.baseImageUrl + addKeyToImage(res?.files[0]?.file_url, 2, true, false, false, true) : "/asset/image/No_Image_Available.webp",
                    alt: res?.files[0]?.alt ?? "",
                    title: res?.files[0]?.alt ?? "",
                })

                await setDetail({
                    data: res,
                    attributeValues: handleAttributeValues(res, res?.product_details[0] ?? null),
                    selectProductDetail: product_detail ?? null,
                    accessories: accessories,
                    totalAmount: res.is_product === false ? (res.amount > 0 ? 1 : 0) : (checkAvailableTotalAmount(res?.product_details[0]) ? 1 : 0),
                    totalRentPrice: 0,
                    totalSellPrice: 0,
                    surchargeDate: 0,
                });
            }
        }
    }

    async function handleChangeActiveAttributeValue(parent_id: number, id: number) {
        for (let i = 0; i < (detail?.data?.product_details.length ?? 0); i++) {
            if (detail?.data?.product_details[i].attribute_values?.includes(id)) {
                let totalAmount = hasToIncreaseTotalAmount({
                    ...detail,
                    selectProductDetail: { ...detail?.data?.product_details[i], is_product: detail?.data.is_product ?? true },
                    accessories: handleAccessories(detail?.data?.product_details[i])
                }, handleAccessories(detail?.data?.product_details[i])) ?? 0;
                await setDetail({
                    ...detail,
                    selectProductDetail: detail?.data?.product_details[i],
                    accessories: handleAccessories(detail?.data?.product_details[i]),
                    totalAmount: totalAmount === Infinity ? (detail?.data?.is_product === false ? detail?.data?.amount : checkUndefinedNumber({ ...detail?.data?.product_details[i], is_product: detail?.data.is_product ?? true }?.amount)) > 0 ? 1 : 0 : totalAmount
                })
                checkAllowActionSell({
                    ...detail,
                    selectProductDetail: detail?.data?.product_details[i],
                    accessories: handleAccessories(detail?.data?.product_details[i]),
                    totalAmount: totalAmount === Infinity ? (detail?.data?.is_product === false ? detail?.data?.amount : checkUndefinedNumber({ ...detail?.data?.product_details[i], is_product: detail?.data.is_product ?? true }?.amount)) > 0 ? 1 : 0 : totalAmount
                });
                checkAllowActionRent({
                    ...detail,
                    selectProductDetail: detail?.data?.product_details[i],
                    accessories: handleAccessories(detail?.data?.product_details[i]),
                    totalAmount: totalAmount === Infinity ? (detail?.data?.is_product === false ? detail?.data?.amount : checkUndefinedNumber({ ...detail?.data?.product_details[i], is_product: detail?.data.is_product ?? true }?.amount)) > 0 ? 1 : 0 : totalAmount
                });
                return;
            }
        }
    }

    function onChangeTotalAmount(value: number) {
        let cloneAccessories = [...detail.accessories];
        let isInValid = false;

        if (
            value > detail.totalAmount

        ) {

            let diff = value - detail.totalAmount;
            if (detail?.selectProductDetail?.is_set === false) {
                if (detail?.data?.is_product === false) {
                    cloneAccessories = [...detail.accessories]?.map((e: any) => {
                        e.count += diff;
                        if (detail?.data?.is_product === false) {
                            e.count = value * e.product_amount;
                        }
                        if (e.count > e.amount) {
                            // isInValid = true;
                        }
                        if (e.count < 0) {
                            e.count = 0;
                        }
                        return e;
                    })
                }
            } else {
                cloneAccessories = [...detail.accessories]?.map((e: any) => {
                    e.count += diff;
                    if (detail?.data?.is_product === false) {
                        e.count = value * e.product_amount;
                    }
                    if (e.count > e.amount) {
                        // isInValid = true;
                    }
                    if (e.count < 0) {
                        e.count = 0;
                    }
                    return e;
                })
            }

        } else {
            let diff = detail.totalAmount - value;
            if (detail?.selectProductDetail?.is_set === false) {
                if (detail?.data?.is_product === false) {
                    cloneAccessories = [...detail.accessories]?.map((e: any) => {
                        e.count += diff;
                        if (detail?.data?.is_product === false) {
                            e.count = value * e.product_amount;
                        }
                        if (e.count > e.amount) {
                            // isInValid = true;
                        }
                        if (e.count < 0) {
                            e.count = 0;
                        }
                        return e;
                    })
                }
            } else {
                cloneAccessories = [...detail.accessories]?.map((e: any) => {
                    e.count -= diff;
                    if (detail?.data?.is_product === false) {
                        e.count = value * e.product_amount;
                    }
                    if (e.count < 0) {
                        e.count = 0;
                    }
                    if (e.count > e.amount) {
                        //isInValid = true;
                    }
                    return e;
                })
            }
        }

        if (isInValid) {
            toast.error("Số lượng của món đồ không khả dụng")
            return
        }
        setDetail({
            ...detail,
            totalAmount: value,
            accessories: cloneAccessories
        })
    }

    // function hasToIncreaseTotalAmount(accessories: any) {
    //     let array = accessories?.map((e: any) => e.count);

    //     let lowestNumber = Math.min.apply(Math, array);
    //     for (let i = 0; i < array?.length; i++) {
    //         if (array[i] < lowestNumber) {
    //             return detail.totalAmount;
    //         }
    //     }
    //     return lowestNumber;
    // }

    function maxTotalAmount(accessories: any) {
        let array = accessories?.map((e: any) => e.amount);

        let lowestNumber = Math.min.apply(Math, array);
        for (let i = 0; i < array?.length; i++) {
            if (array[i] < lowestNumber) {
                return detail.totalAmount;
            }
        }
        return lowestNumber;
    }

    function onChangeAccessorieAmount(value: number, id: number) {
        let cloneAccessories = [...detail.accessories]?.map((e: any) => {
            if (e.id === id) {
                e.count = value;
            }
            return e;
        });
        let totalAmount = hasToIncreaseTotalAmount(detail, cloneAccessories);
        setDetail({
            ...detail,
            accessories: cloneAccessories,
            totalAmount: totalAmount
        })
    }

    function diffInDay(d1: Date, d2: Date) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    }

    function calcSurchargeDate(takeDate: any, returnDate: any) {
        takeDate = takeDate ?? date?.takeDate;
        returnDate = returnDate ?? date?.returnDate;
        if (takeDate === null || returnDate === null) {
            return
        }
        let diff = diffInDay(takeDate, returnDate) + 1 - checkUndefinedNumber(detail?.data?.time_rent_package);

        if (diff < 0) {
            setDetail({
                ...detail,
                surchargeDate: 0
            });
        } else {
            setDetail({
                ...detail,
                surchargeDate: diff
            });
        }
    }

    function checkAllowActionSell(detail: any) {
        let isAllow = false;
        if (detail?.selectProductDetail?.accessories.length > 0) {

            for (let index = 0; index < detail?.selectProductDetail?.accessories.length; index++) {
                const access = detail?.selectProductDetail?.accessories[index];

                if (access.count > 0) {
                    isAllow = true;
                }
            }
        } else {
            isAllow = detail?.totalAmount > 0;
        }

        setIsAllowAction(isAllow);
        return isAllow;
    }

    function checkAllowActionRent(detail: any) {
        let isAllow = false;
        if (detail?.selectProductDetail?.accessories.length > 0) {

            for (let index = 0; index < detail?.selectProductDetail?.accessories.length; index++) {
                const access = detail?.selectProductDetail?.accessories[index];

                if (access.count > 0) {
                    isAllow = true;
                }
            }
        } else {
            isAllow = detail?.totalAmount <= detail?.selectProductDetail?.amount;
            if (detail?.totalAmount == 0) {
                isAllow = false
            }

        }
        if (isAllow == true) {
            if (date && date.takeDate && date.returnDate) {
                if (date.takeDate < date.returnDate) {
                    isAllow = true
                } else {
                    isAllow = false
                }
            }
            else {
                isAllow = false
            }
        }
        setIsAllowActionRent(isAllow);
        return isAllow;
    }


    useEffect(() => {
        setIsLogin(localStorage.getItem(constant.username) ? true : false);
        loadProductDetail();
        setHost(window.location.origin + "/");
    }, []);

    useEffect(() => {
        let doc = document.getElementById("description_product");
        if (doc) {
            doc.innerHTML = detail?.data?.content ?? " ";
        }
        checkAllowActionSell(detail);
        checkAllowActionRent(detail);
    }, [detail && date])

    useEffect(() => {
        if (detail?.data?.is_product === false) {
            callUpdatePrice(detail?.accessories, date)
        }
    }, [date.takeDate && date.returnDate]);

    const handleAddToCart = (isSell: boolean | null) => {
        let isValid = true;
        // if (detail?.data?.is_product === false) {
        //     detail.accessories?.map((e: any) => {
        //         if (e.count > e.amount) {
        //             isValid = false
        //         }
        //         return e;
        //     })
        // }
        if (isValid) {
            addToCart(
                workingDay,
                holidays,
                sellCarts,
                rentCarts,
                isSell ? setSellCarts : setRentCarts,
                isSell ? sellCarts : rentCarts,
                {
                    ...detail.selectProductDetail,
                    attribute_values: detail.attributeValues,
                    take_date: date?.takeDate,
                    return_date: date?.returnDate,
                    temp_accessories: detail.accessories,
                    attribute_choose: detail.selectProductDetail.attribute_values,
                    is_product: detail?.data?.is_product,
                    files: detail.data.files,
                },
                isSell,
                detail.totalAmount
            );
            return true
        } else {
            toast.error("Số lượng sản phẩm không khả dụng");
            return false
        }
    }



    async function onTryOnSchedule() {
        try {
            if (!date?.takeDate || !selectBranch) {
                toast.error("Vui lòng chọn chi nhánh và ngày thử đồ")
            } else {
                let data = {
                    id: detail.selectProductDetail?.id,
                    customer: {
                        name: localStorage.getItem(STORAGE_KEYS.name),
                        phone: localStorage.getItem(STORAGE_KEYS.phone),
                        email: localStorage.getItem(STORAGE_KEYS.email)
                    },
                    try_on_date: date?.takeDate,
                    branch_id: selectBranch,
                    product_detail_code: detail?.selectProductDetail?.code,
                    product_accessories: detail?.accessories
                };
                let res = await postTryOnSchedule(data);
                if (res) {
                    setIsOpen(false);
                }
            }

        } catch (error) {

        }
    }

    return <div id="map_popup" className="wrap_popup">
        <div className="order_pop mt-[1rem] flex flex-row w-[1046px] overflow-hidden">
            <div className="flex-1 w-1/2 flex flex-col pt-4">
                <div className="flex flex-row gap-2 w-full pl-2 pb-3">
                    <Image
                        src={"/asset/icon/ico_product_notice.svg"}
                        alt="arrow_right"
                        width={40}
                        height={40}
                    />
                    <div className="flex flex-col gap-1">
                        <div className="order_pop--header--main">{orderType === IOrderType.orderCalendar ? "Đặt lịch" : orderType === IOrderType.sell ? "Đặt mua" : "Đặt thuê"}</div>
                        <div className="order_pop--header--second break_2">{detail?.data?.name ?? ""}</div>
                    </div>
                </div>
                <div className='w-full h-full relative overflow-hidden'>
                    {detail?.data?.youtube_url ? <div className='w-full h-full relative'>
                        <div className="flex number_cart z-[2] top-[10px] right-[16px]">
                            Mã: {detail?.data?.is_product === true ? detail?.selectProductDetail?.product_code : detail?.data?.product_code}
                        </div>
                        {/* <iframe loading="lazy" className='w-full h-full' src={detail?.data?.youtube_url ?? ""} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                        <VideoPlayer className='w-full h-full' url={detail?.data?.youtube_url ?? ""} />
                    </div> : <div className='w-full h-full relative'>
                        <div className="flex number_cart z-[2] top-[10px] right-[16px]">
                            Mã: {detail?.data?.is_product === true ? detail?.selectProductDetail?.product_code : detail?.data?.product_code}
                        </div>
                        <Image
                            className="w-full relative"
                            src={selectImage.image}
                            alt={selectImage.alt}
                            title={selectImage.title}
                            layout='fill'
                            objectFit='cover'
                        />
                    </div>}
                </div>
                {detail?.data?.files.length > 1 && <div className='w-full my-4 mx-4 max-h-[250px] lg:max-h-[180px] xl:max-h-[200px] max-w-[660px] '>
                    <DynamicSlider {...popUpsettings}>
                        {
                            detail?.data?.youtube_url ? detail?.data?.files?.map((item: any) => {
                                return <div
                                    key={item.id}
                                    onClick={() => {
                                        setSelectImage({
                                            image: item?.file_url ? environment.baseImageUrl + addKeyToImage(item?.file_url, windowWidth, false, false, true, true) : "/asset/image/No_Image_Available.webp",
                                            alt: item?.alt ?? "",
                                            title: item?.alt ?? "",
                                        })
                                    }}
                                    className="relative rounded-[4px] h-[187px] w-[177px] overflow-hidden cursor-pointer">
                                    <Image
                                        key={item.id}
                                        className="rounded-[4px] overflow-hidden"
                                        src={item?.file_url ? environment.baseImageUrl + addKeyToImage(item?.file_url, windowWidth, false, false, true) : "/asset/image/No_Image_Available.webp"}
                                        alt={item?.alt}
                                        title={item?.alt}
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </div>
                            }) : detail?.data?.files?.map((item: any) => {
                                return <div
                                    key={item.id}
                                    onClick={() => {
                                        setSelectImage({
                                            image: item?.file_url ? environment.baseImageUrl + addKeyToImage(item?.file_url, windowWidth, false, false, true, true) : "/asset/image/No_Image_Available.webp",
                                            alt: item?.alt ?? "",
                                            title: item?.alt ?? "",
                                        })
                                    }}
                                    className="relative h-[187px] w-[177px] rounded-[4px] overflow-hidden cursor-pointer">
                                    <Image
                                        key={item.id}
                                        className="rounded-[4px] overflow-hidden"
                                        src={item?.file_url ? environment.baseImageUrl + addKeyToImage(item?.file_url, windowWidth, false, false, true) : "/asset/image/No_Image_Available.webp"}
                                        alt={item?.alt}
                                        title={item?.alt}
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </div>
                            })

                        }
                    </DynamicSlider>
                </div>}
            </div>
            <div className="flex-1 flex flex-col w-1/2">
                <div className="flex flex-row w-full justify-between py-4 pl-[24px] pr-[40px]">
                    <h1 className="text-[#434343] text-xl font-bold w-full uppercase break_2 ">{detail?.data?.name ?? ""}</h1>
                    <a className="close" onClick={() => { setIsOpen(false) }}>&times;</a>
                </div>
                <div className="flex flex-col h-full">
                    <div className="flex flex-col">
                        <div className='product_detail_right_upper px-[24px] mb-[16px] flex flex-col gap-3'>
                            <AttributeValuesRender
                                key={"main_attr"}
                                isDisable={false}
                                handleChangeActive={handleChangeActiveAttributeValue}
                                attributeValues={detail?.attributeValues}
                                selectProductDetail={detail?.selectProductDetail} />

                            {detail?.data?.attribute_values_view?.length > 0 && <div className='product_detail_right--row'>
                                <div className='product_detail_right--h text-[#434343] font-[700] text-[13px] lg:text-[15px] lg:leading-[20px]'>Thuộc tính:</div>
                                <div className='product_detail_right--row--boxes text-sm'>
                                    {
                                        detail?.data?.attribute_values_view?.map((e: any, indexPar: number) => e.values?.map((item: any, index: number) => {
                                            if (indexPar == 0) {
                                                return item.key
                                            }
                                            return ", " + item.key;
                                        })).join("")
                                    }
                                </div>
                            </div>}
                            <div className='flex flex-row gap-1 mt-4'>
                                <div className='my-auto'>
                                    <Image
                                        src={"/asset/icon/ico_ruler.svg"}
                                        alt="arrow_right"
                                        width={16}
                                        height={16}
                                    />
                                </div>
                                <span onClick={() => {
                                    if (detail?.data?.size_guide?.file_url) {
                                        setIsOpenSizeGuide(true)
                                    }
                                }} className={'text-sm text-[#434343] ' + (!detail?.data?.size_guide?.file_url ? "cursor-not-allowed" : "")}>Hướng dẫn chọn size</span>
                            </div>
                            <div className='product_detail_right--row'>
                                <span className='product_detail_right--h text-sm lg:text-base'>Số lượng</span>
                                <InputNumber
                                    defaultValue={0}
                                    disabled={false}
                                    //max={detail?.data?.is_product === false ? detail?.data?.amount : detail?.selectProductDetail?.is_set === false ? detail?.selectProductDetail?.amount : maxTotalAmount(detail?.accessories)}
                                    key={"totalAmount"} value={detail?.totalAmount}
                                    onChange={onChangeTotalAmount} />
                                {detail?.selectProductDetail?.is_set === false && <span className='ml-3 text-sm text-[#8c8c8c]'>Còn lại trong kho: {detail?.data?.is_product === false ? detail?.data?.amount : checkUndefinedNumber(detail?.selectProductDetail?.amount)}</span>}
                            </div>
                            {/* {detail?.selectProductDetail?.is_set === false && <span className='text-sm text-[#8c8c8c]'>Còn lại trong kho: {checkUndefinedNumber(detail?.selectProductDetail?.amount)}</span>} */}
                            <div className='flex flex-row gap-10 items-center'>
                                {detail?.selectProductDetail?.rental_price ? <div className='flex flex-row gap-1 items-center'>
                                    <span className='text-base text-[#434343]'>Giá thuê</span>
                                    <span className='text-base font-bold text-[#E03616]'>{detail?.selectProductDetail?.rental_price ? currencyWithNoFormat(detail?.selectProductDetail?.rental_price) : ""}</span>
                                    <span className='line-through text-sm text-[#bfbfbf]'>{detail?.selectProductDetail?.origin_rental_price ? currencyWithNoFormat(detail?.selectProductDetail?.origin_rental_price) : ""}</span>
                                </div> : ""}
                                <div className='flex flex-row gap-1 items-center'>
                                    <span className='text-base text-[#434343]'>Giá bán</span>
                                    <span className='text-base font-bold text-[#E03616]'>{detail?.selectProductDetail?.price ? currencyWithNoFormat(detail?.selectProductDetail?.price) : ""}</span>
                                    <span className='line-through text-sm text-[#bfbfbf]'>{detail?.selectProductDetail?.origin_price ? currencyWithNoFormat(detail?.selectProductDetail?.origin_price) : ""}</span>
                                </div>
                            </div>
                        </div>
                        <Tab isHideDescr={detail?.data?.description ? true : false} activeIndex={activeTab} setActiveIndex={setActiveTab} isHideSP={detail?.data?.is_product === false ? false : detail?.selectProductDetail?.is_set === false} />
                    </div>

                    <div className='product_detail_right_middle overflow-hidden flex-1'>
                        <RenderTab key="renderTab" detail={detail} activeTab={activeTab} />
                    </div>
                    {orderType === IOrderType.rent && <div className="bg-white popup_order_near_bottom flex flex-row z-[10]">
                        <div className="flex flex-col px-4 py-3 popup_order_near_bottom_inner">
                            <span className="text-sm text-[#434343] mb-[4px]">Thời gian thuê đồ</span>
                            <div className="flex flex-row gap-4">
                                <DatePicker
                                    key={"takeDate"}
                                    status={date ? date.takeDate > date.returnDate ? "error" : "" : ""}
                                    disabledDate={(current) => {
                                        return current && current < moment().startOf('day');
                                    }}
                                    defaultValue={date?.takeDate ? dayjs(date?.takeDate) : undefined}
                                    onChange={async (e: any) => {
                                        await calcSurchargeDate(new Date(e), null)
                                        await setDate({
                                            ...date,
                                            takeDate: new Date(e)
                                        });


                                    }}
                                    placeholder="Ngày lấy" />
                                <DatePicker
                                    status={date ? date.takeDate > date.returnDate ? "error" : "" : ""}
                                    disabledDate={(current) => {
                                        return current && current < moment().startOf('day');
                                    }}
                                    defaultValue={date?.returnDate ? dayjs(date?.returnDate) : undefined}
                                    key={"returnDate"}
                                    onChange={async (e: any) => {
                                        await calcSurchargeDate(null, new Date(e));
                                        await setDate({
                                            ...date,
                                            returnDate: new Date(e)
                                        })


                                    }}
                                    placeholder="Ngày trả" />
                            </div>
                            <div className='flex text-base text-red-500'>
                                {date ? date.takeDate > date.returnDate ? "Ngày lấy phải bé hơn ngày trả" : "" : ""}
                            </div>
                        </div>
                        <div className="pr-4 pl-[8px] w-1/3 flex flex-row gap-[8px] mt-6">
                            <Link target="_blank" rel="noopener noreferrer"
                                //href={host + pathRouter.GOITHUE} 
                                href={{ pathname: '/[...group]', query: { group: ['chinh-sach', 'goi-thue'] } }}
                                className='rent_pack_shadow flex flex-col p-[10px] text-[10px] text-[#8c8c8c] items-center justify-center gap-1'>
                                <Image
                                    src={"/asset/icon/ico_product_notice.svg"}
                                    alt="arrow_right"
                                    width={16}
                                    height={16}
                                />
                                <span>Gói thuê</span>
                            </Link>
                            <Link target="_blank" rel="noopener noreferrer"
                                //href={host + pathRouter.QUYDINHTHUE} 
                                href={{ pathname: '/[...group]', query: { group: ['chinh-sach', 'quy-dinh-thue'] } }}
                                className='rent_policy_shadow flex flex-col p-[10px] text-[10px] text-[#8c8c8c] items-center justify-center gap-1'>
                                <Image
                                    src={"/asset/icon/ico_product_notice.svg"}
                                    alt="arrow_right"
                                    width={16}
                                    height={16}
                                />
                                <span>Quy định thuê</span>
                            </Link>
                        </div>

                    </div>}

                    <div className='flex flex-col p-4 popup_order_bottom z-[10] bg-white'>
                        {orderType === IOrderType.orderCalendar && <div className="flex flex-row gap-4 order_pop--form mb-4">
                            <div className="flex flex-col gap-1 flex-1">
                                <span className="text-sm text-[#8C8C8C]">Chọn ngày thử đồ</span>
                                <DatePicker
                                    key={"takeDate"}
                                    defaultValue={date?.takeDate ? dayjs(date?.takeDate) : undefined}
                                    onChange={async (e: any) => {
                                        await calcSurchargeDate(new Date(e), null)
                                        await setDate({
                                            ...date,
                                            takeDate: new Date(e)
                                        });


                                    }}
                                    placeholder="Ngày lấy" />
                            </div>
                            <div className="flex flex-col gap-1 flex-1">
                                <span className="text-sm text-[#8C8C8C]">Chọn chi nhánh thử đồ</span>
                                <Select
                                    key={"takeDate"}
                                    options={branchs?.map((e: any) => {
                                        return {
                                            value: e.id,
                                            label: e.name
                                        }
                                    })}
                                    onChange={async (e: any) => {
                                        setSelectBranch(e);
                                    }}
                                    placeholder="Chọn chi nhánh" />
                            </div>
                        </div>}
                        <div className='product_detail_right_bottom shadow-none p-0'>
                            <div className='flex flex-col product_detail_right_bottom--content'>
                                <span className='product_detail_right_bottom--sum'>Tổng tiền của bộ đồ</span>
                                <span className='product_detail_right_bottom--detail text-xs xl:text-sm'>(Gói thuê: {checkUndefinedNumber(detail?.data?.time_rent_package)} ngày- phụ thu: {detail?.surchargeDate} ngày)</span>
                                <span className='product_detail_right_bottom--rent text-sm xl:text-base font-bold'>Giá thuê: {currencyFormatter.format(calcTotalRentPrice(detail))}</span>
                                <span className='product_detail_right_bottom--rent text-sm xl:text-base font-bold'>Giá bán: {currencyFormatter.format(calcTotalSellPrice(detail))}</span>
                            </div>
                            <div className='product_detail_right_bottom--boxes items-center hidden md:flex'>
                                {detail?.selectProductDetail?.allowed_sell && orderType === IOrderType.rent && <div className={'box relative group ' + (!isAllowActionRent ? "cursor-not-allowed" : "cursor-pointer")}>
                                    <div className='flex flex-col gap-1'>
                                        <Image
                                            className='m-auto'
                                            src={"/asset/icon/ico_rent_product.svg"}
                                            alt="arrow_right"
                                            width={40}
                                            height={40}
                                        />
                                        <span className='text-xs xl:text-base'>Đặt thuê</span>
                                    </div>
                                    <div className='z-[9] hidden group-hover:flex absolute flex-col w-full h-full rounded-[24px] overflow-hidden'>
                                        {isAllowActionRent ? <div onClick={() => {
                                            if (handleAddToCart(false)) {
                                                router.push("/carts");
                                            }
                                        }} className={'flex-1 text-sm text-[#fff] bg-[#595959] hover:text-[#13C2C2] hover:bg-white w-full flex items-center justify-center h-full ' + (!isAllowActionRent ? "cursor-not-allowed" : "")}>
                                            Thuê ngay
                                        </div> :
                                            <div className={'flex-1 text-sm text-[#fff] bg-[#595959] hover:text-[#13C2C2] hover:bg-white w-full flex items-center justify-center h-full ' + (!isAllowActionRent ? "cursor-not-allowed" : "")}>
                                                Thuê ngay
                                            </div>}
                                        <button onClick={() => {
                                            if (isAllowActionRent) {
                                                handleAddToCart(false)
                                            }
                                        }} className={'flex-1 text-sm text-[#fff] bg-[#595959] hover:text-[#13C2C2] hover:bg-white w-full flex items-center justify-center h-full ' + (!isAllowActionRent ? "cursor-not-allowed" : "")}>
                                            Đặt thuê
                                        </button>
                                    </div>
                                </div>}
                                {orderType === IOrderType.sell && <div className='box cursor-pointer relative group'>
                                    <div className='flex flex-col gap-1'>
                                        <Image
                                            className='m-auto'
                                            src={"/asset/icon/ico_buy_product.svg"}
                                            alt="arrow_right"
                                            width={40}
                                            height={40}
                                        />
                                        <span className='text-xs xl:text-base'>Đặt mua</span>
                                    </div>
                                    <div className='z-[9] hidden group-hover:flex absolute flex-col w-full h-full rounded-[24px] overflow-hidden'>
                                        {isAllowAction ? <Link
                                            //href={"/carts"} 
                                            href={{ pathname: '/[...group]', query: { group: ['carts'] } }}
                                            onClick={() => {
                                                if (isAllowAction) {
                                                    handleAddToCart(true)
                                                }
                                            }} className={'flex-1 text-sm text-[#fff] bg-[#595959] hover:text-[#13C2C2] hover:bg-white w-full flex items-center justify-center h-full ' + (!isAllowAction ? "cursor-not-allowed" : "")}>
                                            Mua ngay
                                        </Link> :
                                            <div className={'flex-1 text-sm text-[#fff] bg-[#595959] hover:text-[#13C2C2] hover:bg-white w-full flex items-center justify-center h-full ' + (!isAllowAction ? "cursor-not-allowed" : "")}>
                                                Mua ngay
                                            </div>}
                                        <button onClick={() => {
                                            if (isAllowAction) {
                                                handleAddToCart(true)
                                            }
                                        }} className={'flex-1 text-sm text-[#fff] bg-[#595959] hover:text-[#13C2C2] hover:bg-white w-full flex items-center justify-center h-full ' + (!isAllowAction ? "cursor-not-allowed" : "")}>
                                            Đặt mua
                                        </button>
                                    </div>
                                </div>}
                                {orderType === IOrderType.orderCalendar && <div className='box cursor-pointer' onClick={onTryOnSchedule}>
                                    <div className={'flex flex-col gap-1 ' + (!isAllowAction ? "cursor-not-allowed" : "cursor-pointer")}>
                                        <Image
                                            className='m-auto'
                                            src={"/asset/icon/ico_plan_product.svg"}
                                            alt="arrow_right"
                                            width={40}
                                            height={40}
                                        />
                                        <span className='text-xs xl:text-base'>Đặt lịch thử đồ</span>
                                    </div>

                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            isOpenSizeGuide && <PopupSizeGuide isOpen={isOpenSizeGuide} setIsOpen={() => {
                setIsOpenSizeGuide(false)
            }} url={detail?.data?.size_guide?.file_url} />
        }
    </div>
}

export default PopupOrder;
